import classNames from 'classnames';

import { MEMBERSHIPLEVEL } from '@tb-core/components/behavior/progress';
import Line from '@tb-core/components/simple/line';
import Svg from '@tb-core/components/simple/svg';
import { InfoBar } from '@tb-core/components/styled/info-bar';
import { InfoTooltip } from '@tb-core/components/styled/info-tooltip';
import { interpolate } from '@tb-core/helpers/interpolate';
import { isLoyaltyLifetimePointsEnabled } from '@tb-core/helpers/next-env';
import { JsxChildren, RealObject } from '@tb-core/types';
import styles from './styles.module.scss';

interface LoyaltyProgressProps {
    currentLevelTitle: string;
    iconIndicator: JsxChildren;
    levelIconColors: RealObject;
    levelNormalized: string;
    lifetimePointsLabel: string;
    membershipConversionLimit: number;
    // fractional representing total progress out of a maximum 1 (0, .2, .5, 1, etc)
    membershipLevelProgress: number | null;
    nextLevelTooltips: RealObject;
    nextLevel: string;
    pointsAccumulated: number;
    pointsThisYear: number;
    progressPointsExceededLabel: string;
    progressPointsLabel: string;
    theme: RealObject;
    totalLifeTimePoints: number;
}

const progressBarWidth = (
    nextLevel: string,
    pointsThisYear: number,
    membershipConversionLimit: number,
    membershipLevelProgress: number
) => {
    if (
        isLoyaltyLifetimePointsEnabled &&
        nextLevel === MEMBERSHIPLEVEL.MAINTAIN_FIRE
    ) {
        return membershipConversionLimit !== 0
            ? `${(pointsThisYear / membershipConversionLimit) * 100}%`
            : '0%';
    }
    return `${membershipLevelProgress * 100}%`;
};

const renderPoints = (
    total: number,
    threshold: number,
    label: string,
    labelExceeded: string,
    pointsThisyear: number
) =>
    total > threshold && !isLoyaltyLifetimePointsEnabled ? (
        <span className={styles['progress-point']}>
            {interpolate(labelExceeded, { total })}
        </span>
    ) : (
        <span className={styles['progress-point']}>
            {interpolate(label, { total: pointsThisyear })}
        </span>
    );

const LoyaltyProgressBar = ({
    currentLevelTitle,
    iconIndicator,
    levelNormalized,
    lifetimePointsLabel,
    membershipConversionLimit,
    membershipLevelProgress = null,
    nextLevelTooltips,
    nextLevel,
    pointsAccumulated,
    pointsThisYear,
    progressPointsExceededLabel = '',
    progressPointsLabel = '',
    theme,
    totalLifeTimePoints
}: LoyaltyProgressProps) => (
    <>
        {typeof membershipLevelProgress === 'number' && (
            <div className={styles['progress-drawer']}>
                <div className={styles['level-tier']}>
                    <span>{currentLevelTitle}</span>
                    <span className={styles['graphic-indicator']}>
                        {iconIndicator}
                    </span>
                </div>
                <div className={styles['level-progress-meter']}>
                    <svg
                        className={classNames(
                            styles['progress-bar'],
                            styles['progress-bar-bg']
                        )}
                    >
                        <Line
                            lineColor={theme.neutral}
                            xEnd="100%"
                            xStart="0"
                            yEnd="0"
                            yStart="0"
                        />
                    </svg>
                    <svg
                        className={classNames(
                            styles['progress-bar'],
                            styles['progress-bar-fg']
                        )}
                        style={{
                            width: progressBarWidth(
                                nextLevel,
                                pointsThisYear,
                                membershipConversionLimit,
                                membershipLevelProgress
                            )
                        }}
                    >
                        <Line
                            lineColor={theme[levelNormalized]}
                            xEnd="100%"
                            xStart="0"
                            yEnd="0"
                            yStart="0"
                        />
                    </svg>
                </div>
                <div className={styles['progress-stats']}>
                    <span className={styles['level-unlock']}>{nextLevel}</span>
                    <div className={styles.top}>
                        {isLoyaltyLifetimePointsEnabled ? (
                            <InfoTooltip
                                iconSVGElement={
                                    <Svg
                                        svgId="icon-unclock"
                                        width={25}
                                        height={25}
                                    />
                                }
                                position={'bottom'}
                                raise={37}
                                textString={
                                    nextLevelTooltips[nextLevel?.toUpperCase()]
                                        .replace(
                                            '{pointsNeeded}',
                                            membershipConversionLimit -
                                                pointsThisYear
                                        )
                                        .replace(
                                            '{year}',
                                            (
                                                new Date().getFullYear() + 1
                                            ).toString()
                                        ) || ''
                                }
                            ></InfoTooltip>
                        ) : null}
                    </div>
                    {renderPoints(
                        pointsAccumulated,
                        membershipConversionLimit,
                        progressPointsLabel,
                        progressPointsExceededLabel,
                        pointsThisYear
                    )}
                </div>
                {isLoyaltyLifetimePointsEnabled &&
                totalLifeTimePoints !== null &&
                totalLifeTimePoints !== undefined ? (
                    <InfoBar
                        iconSVGElement={
                            <Svg svgId="icon-union" width={38} height={18} />
                        }
                        textString={lifetimePointsLabel?.replace(
                            '{lifeTimePoints}',
                            totalLifeTimePoints.toString()
                        )}
                    />
                ) : null}
            </div>
        )}
    </>
);

export default LoyaltyProgressBar;
