import {
    getCurrentQuarter,
    getWisdomSignifier
} from '@tb-core/components/behavior/dashboard/helpers';
import LoyaltyDashboard from '@tb-core/components/styled/loyalty-dashboard/logged-in';
import { interpolate } from '@tb-core/helpers/interpolate';
import {
    LoyaltyDashboardResponseBody,
    LoyaltyDashboardTemplateProps
} from '@tb-core/types';

interface DashboardProps {
    firstName?: string;
    isLoggedIn?: boolean;
}

const Dashboard = ({
    bgDoodleUnderlineImage = '',
    challenges = [],
    challengesProps = {},
    currentCustomerTierLevel = '',
    customerGreeting = '',
    customerGreetingDoodleImg = '',
    downloadAppCopy = '',
    firstName,
    isLoggedIn,
    levelIconColors = {},
    loggedInDashBoardBg = '',
    loggedInPaperTexture = '',
    lifetimePointsLabel = '',
    membership_conversion_limit = 0,
    membership_level_name = '',
    membership_level_progress = 0,
    nextLevelTooltips = {},
    points_accumulated = 0,
    points_this_year = 0,
    points_conversion_threshold = 0,
    points_to_next_reward = 0,
    pointsEarnedTowardFreeFood = '',
    pointsUntilNextFreeItem = '',
    progressPointsExceededLabel = '',
    progressPointsLabel = '',
    rewards = [],
    total_lifetime_points = 0,
    rewardsFaq,
    rewardsLearnMore,
    rewardsProps = {},
    svgCircleDescription = '',
    svgCircleTitle = '',
    theme = {},
    tierLevels = [],
    unlockLabel = '',
    unlockedLabel = '',
    wisdomSignifiers = {}
}: DashboardProps &
    Partial<LoyaltyDashboardResponseBody> &
    LoyaltyDashboardTemplateProps) => {
    const currentMembershipPoints =
        membership_conversion_limit * membership_level_progress;
    const currentQuarter = getCurrentQuarter(currentMembershipPoints);
    const wisdomSignifier: string = getWisdomSignifier(
        wisdomSignifiers[membership_level_name][currentQuarter]
    );

    const numChallenges = challenges.length ? ` (${challenges.length})` : '';
    const numRewards = rewards.length ? ` (${rewards.length})` : '';

    const buttonGroup = [
        {
            colorTheme: {
                'background-color': 'transparent',
                'border-color': theme?.textColor,
                color: theme?.textColor
            },
            label: rewardsProps.button + numRewards,
            title: rewardsProps.title
        },
        {
            colorTheme: {
                'background-color': 'transparent',
                'border-color': theme?.textColor,
                color: theme?.textColor
            },
            label: challengesProps.button + numChallenges,
            title: challengesProps.title
        }
    ];
    const ctaGroup = [
        {
            colorTheme: {
                'background-color': theme?.dashboardNumberColor,
                'border-color': theme?.dashboardNumberColor,
                color: theme?.white
            },
            gaDataLayerConfig: {
                'Analytics-Action': 'Rewards > Logged In',
                'Analytics-Value': `Rewards Dashboard > ${rewardsProps.cta}`
            },
            label: rewardsProps.cta + numRewards,
            url: rewardsProps.url
        },
        {
            colorTheme: {
                'background-color': 'transparent',
                'border-color': theme?.dashboardNumberColor,
                color: theme?.dashboardNumberColor
            },
            gaDataLayerConfig: {
                'Analytics-Action': 'Rewards > Logged In',
                'Analytics-Value': `Rewards Dashboard > ${challengesProps.cta}`
            },
            label: challengesProps.cta + numChallenges,
            url: challengesProps.url
        }
    ];
    const customerGreetingName = interpolate(customerGreeting, {
        firstName
    });
    const pointsToNextReward = points_to_next_reward;
    const nextReward = interpolate(pointsUntilNextFreeItem, {
        pointsToNextReward
    });

    const loyaltyDashboardProps = {
        archProps: {
            bgDoodleUnderlineImage,
            fill: 'none',
            pointsAccumulated:
                points_conversion_threshold - points_to_next_reward,
            pointsConversionThreshold: points_conversion_threshold,
            pointsEarnedTowardFreeFood,
            pointsUntilNextFreeItem: nextReward,
            radius: 124,
            stroke: theme['archFg' || '#ca16af'],
            svgCircleDescription,
            svgCircleTitle,
            xAxisCoordinate: 145,
            yAxisCoordinate: 145
        },
        buttonGroup: { buttonGroup },
        ctaGroup: { ctaGroup },
        greetingProps: {
            customerGreetingDoodleImg,
            customerGreetingName,
            wisdomSignifier
        },
        infoProps: {
            downloadAppCopy,
            rewardsFaq,
            rewardsLearnMore
        },
        loggedInDashBoardBg,
        loggedInPaperTexture,
        progressProps: {
            currentCustomerTierLevel,
            levelIconColors,
            lifetimePointsLabel,
            membershipConversionLimit: membership_conversion_limit,
            membershipLevelName: membership_level_name,
            membershipLevelProgress: membership_level_progress,
            nextLevelTooltips,
            pointsAccumulated: points_accumulated,
            pointsThisYear: points_this_year,
            progressPointsExceededLabel,
            progressPointsLabel,
            theme,
            tierLevels,
            totalLifeTimePoints: total_lifetime_points,
            unlockLabel,
            unlockedLabel
        }
    };

    return <>{isLoggedIn && <LoyaltyDashboard {...loyaltyDashboardProps} />}</>;
};

export default Dashboard;
