import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import keyDownHandler from '@tb-core/helpers/form/handle-key-down';

import styles from './styles.module.scss';

/*
 * Example Tooltip Mock: https://app.zeplin.io/project/5f4ddc405a776db48c5155ad/screen/62856b9e81d1690fd7618baa
 * tooltip width intentionally based on nearest 'positioned' elements width (container element),
 * in other words it needs to be put inside a positioned `relative` element with a fixed height.
 * And the amount it should be 'raised' is about equal to the container element's height.
 */
export const InfoTooltip = ({
    iconSVGElement,
    position = 'top',
    raise,
    textString
}: {
    iconSVGElement?: JSX.Element;
    position?: string;
    raise: number;
    textString: string;
}) => {
    const [isShown, setIsShown] = useState(false);
    const show = () => setIsShown(true);
    const hide = () => setIsShown(false);

    const handleKeyUp = useCallback(
        (e: KeyboardEvent) =>
            isShown &&
            keyDownHandler({
                enterEvent: hide,
                escapeEvent: hide,
                event: e,
                spacebarEvent: hide
            }),
        [isShown]
    );

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
    }, [handleKeyUp]);

    return (
        <div
            className={classNames(styles.info, {
                [styles.show]: isShown
            })}
            onFocus={show}
            onMouseEnter={show}
            onBlur={hide}
            onMouseLeave={hide}
            tabIndex={0}
        >
            <button
                aria-label={textString}
                className={styles.icon}
                tabIndex={-1}
            >
                ⓘ
            </button>
            <div
                role="tooltip"
                className={classNames(styles.tooltip, styles[position])}
                style={{ marginBottom: `${raise}px` }}
            >
                {iconSVGElement ? (
                    <span className={styles.tooltipicon}>{iconSVGElement}</span>
                ) : null}
                <span>{textString}</span>
            </div>
            <div
                className={classNames(styles.carrot, styles[position])}
                style={{ bottom: `${raise - 18}px` }}
            ></div>
        </div>
    );
};
