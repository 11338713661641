import styles from './styles.module.scss';

/**
 * Info bar with a gray background and an icon
 * @param iconSVGElement
 * @param textString
 * @constructor
 */
export const InfoBar = ({
    iconSVGElement,
    textString
}: {
    iconSVGElement?: JSX.Element;
    textString: string;
}) => {
    return (
        <div className={styles['info-bar-bg']}>
            <div className={styles['info-bar-text']}>
                {iconSVGElement ? <span>{iconSVGElement}</span> : null}
                <span className={styles.uppercase}>{textString}</span>
            </div>
        </div>
    );
};
