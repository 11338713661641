import classNames from 'classnames';
import { useEffect } from 'react';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Image from '@tb-core/components/styled/image';
import ThemableLinkButton from '@tb-core/components/styled/links/themable-link-button';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { Style } from '@tb-core/types/elements/style';

import styles from './styles.module.scss';

export interface LoyaltyLoggedOutProps {
    androidHref?: string;
    androidLabel?: string;
    assetPath?: string;
    circleDoodleImg?: string;
    footnote?: string;
    iosHref?: string;
    iosLabel?: string;
    loggedOutBgImg?: string;
    loggedOutBgImgAlt?: string;
    logoAlt?: string;
    logoImg?: string;
    paperImg?: string;
    paperImgAlt?: string;
    productImgMobile?: string;
    productImgMobileAlt?: string;
    productImg?: string;
    productImgAlt?: string;
    productSubtitle?: string;
    subtitle?: string;
    title?: string;
}

const LoyaltyLoggedOut = ({
    assetPath = '',
    androidLabel,
    androidHref,
    circleDoodleImg,
    footnote,
    iosHref,
    iosLabel,
    loggedOutBgImg,
    loggedOutBgImgAlt,
    logoAlt,
    logoImg,
    paperImg,
    paperImgAlt,
    productImg,
    productImgAlt,
    productImgMobile,
    productImgMobileAlt,
    subtitle,
    title
}: LoyaltyLoggedOutProps) => {
    const style: Style = {
        '--background-image': `url(${assetPath + circleDoodleImg})`
    };

    useEffect(() => {
        updateDataLayer({
            _event: 'gtm.elementVisibility',
            modalName: 'rewards-sign-up'
        });
    }, []);

    return (
        <div className={styles['loyalty-logged-out']}>
            <Image
                alt={loggedOutBgImgAlt}
                className={styles['loyalty-bg-image']}
                src={loggedOutBgImg}
            />
            <section
                className={classNames(
                    styles['content-group'],
                    styles['bg-pattern']
                )}
            >
                <div className={styles.inner}>
                    <div className={styles['loyalty-content-inner']}>
                        {logoImg && (
                            <Image
                                alt={logoAlt}
                                className={styles['logo-image']}
                                src={logoImg}
                            />
                        )}
                        {title && (
                            <p
                                className={styles.title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        {subtitle && (
                            <p
                                className={styles.subtitle}
                                dangerouslySetInnerHTML={{ __html: subtitle }}
                                style={style}
                            />
                        )}

                        <FlexGridLayout
                            align="stretch"
                            className={classNames([
                                styles.stores,
                                styles['stores-logged-out']
                            ])}
                            justify="center"
                        >
                            {iosHref && (
                                <ThemableLinkButton
                                    border={false}
                                    className={styles['app-store-cta']}
                                    gaDataLayerConfig={{
                                        'Analytics-Action': 'Get the App',
                                        'Analytics-Value': 'App Store'
                                    }}
                                    href={iosHref}
                                    target="_blank"
                                    theme="loud"
                                >
                                    {iosLabel || 'App Store'}
                                </ThemableLinkButton>
                            )}
                            {androidHref && (
                                <ThemableLinkButton
                                    border={false}
                                    className={styles['app-store-cta']}
                                    gaDataLayerConfig={{
                                        'Analytics-Action': 'Get the App',
                                        'Analytics-Value': 'Google Play'
                                    }}
                                    href={androidHref}
                                    target="_blank"
                                    theme="light"
                                >
                                    {androidLabel || 'Google Play'}
                                </ThemableLinkButton>
                            )}
                        </FlexGridLayout>
                    </div>
                    <Image
                        alt={productImgAlt}
                        className={styles.product}
                        src={productImg}
                    />
                    <Image
                        alt={productImgMobileAlt}
                        className={classNames([
                            styles.product,
                            styles['product-mobile']
                        ])}
                        src={productImgMobile}
                    />
                </div>
                <Image
                    alt={paperImgAlt}
                    className={styles['bg-paper-image']}
                    src={paperImg}
                />
            </section>

            <section
                className={classNames(
                    styles['content-group'],
                    styles['bg-pattern-w']
                )}
            >
                <div className={styles.inner}>
                    <p className={styles.legal}>{footnote}</p>
                </div>
            </section>
        </div>
    );
};

export default LoyaltyLoggedOut;
